import React, { memo, useState } from "react"
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap"
import clsx from "clsx"
import * as classes from "./styles.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import { HomepageImage } from "../../../../components/ui"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import "../../../../styles/gravity.css"
import BackgroundImage from "gatsby-background-image"

interface Contact {
  id: string
  title: string
  email: string
  phone: string
  availableTime: string
}

interface Location {
  id: string
  title: string
  addressLine1: string
  addressLine2: string
  addressLine3: string
}

export interface EnquirySection2Props {
  id: string
  title: string
  description: string
  contact: Contact
  location: Location
  background: HomepageImage
}

const EnquirySection2 = memo((props: EnquirySection2Props) => {
  const data = useStaticQuery(graphql`
    query formQueryEnquiryPage {
      wp {
        ...GravityFormSettings
      }
      wpGfForm(databaseId: { eq: 1 }) {
        ...GravityFormFields
      }
    }
  `)

  const [first1Focus, setFirst1Focus] = useState(false)
  const [last1Focus, setLast1Focus] = useState(false)
  const [email1Focus, setEmail1Focus] = useState(false)

  return (
    <BackgroundImage
      className={clsx("contactus-1 section-image", classes.background)}
      {...convertToBgImage(getImage(props?.background?.gatsbyImageData))}
    >
      <Container>
        <Row>
          <Col md="5">
            <h2 className="title">{props?.title}</h2>
            <h4 className="description">{props?.description}</h4>
            <div className="info info-horizontal">
              <div className="icon icon-info">
                <i className="now-ui-icons location_pin"></i>
              </div>
              <div className="description">
                <h4 className="info-title">{props?.location?.title}</h4>
                <p className="description mb-0">
                  {props?.location?.addressLine1}
                </p>
                <p className="description mb-0">
                  {props?.location?.addressLine2}
                </p>
                <p className="description">{props?.location?.addressLine3}</p>
              </div>
            </div>
            <div className="info info-horizontal">
              <div className="icon icon-info">
                <i className="now-ui-icons tech_mobile"></i>
              </div>
              <div className="description">
                <h4 className="info-title">{props?.contact?.title}</h4>
                <p className="description mb-0">{props?.contact?.email}</p>
                <p className="description mb-0">{props?.contact?.phone}</p>
                <p className="description">{props?.contact?.availableTime}</p>
              </div>
            </div>
          </Col>
          <Col className="ml-auto mr-auto" md="5">
            <Card
              className={clsx("card-contact card-raised", classes.cardContact)}
            >
              <div>
                <CardHeader className="text-center">
                  <CardTitle tag="h4">Contact Us</CardTitle>
                </CardHeader>
                <CardBody>
                  <GravityFormForm data={data} />
                </CardBody>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  )
})

export default EnquirySection2

export const query = graphql`
  fragment EnquirySection2Content on EnquirySection2 {
    id
    title
    description
    contact {
      id
      title
      email
      phone
      availableTime
    }
    location {
      id
      title
      addressLine1
      addressLine2
      addressLine3
    }
    background {
      alt
      id
      gatsbyImageData
    }
  }
`
