import React, { memo } from "react"
import Layout from "../components/Layout/index"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import * as sections from "../containers/Enquiry/components/sections"
import EnquiryContainer from "../containers/Enquiry"

interface Props {
  data: {
    enquiry: {
      id: string
      title: string
      image: { id: string; url: string }
      blocks: sections.EnquiryBlock[]
    }
  }
}

const Enquiry = memo((props: Props) => {
  const { enquiry } = props.data

  return (
    <Layout title="Enquiry">
      <SEO title="Enquiry" />
      <EnquiryContainer blocks={enquiry?.blocks} />
    </Layout>
  )
})

export default Enquiry

export const query = graphql`
  {
    enquiry {
      id
      title
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...EnquirySection1Content
        ...EnquirySection2Content
      }
    }
  }
`
