import React, { memo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import clsx from "clsx"
import * as classes from "./styles.module.scss"
import { graphql } from "gatsby"
import { HomepageImage } from "../../../../components/ui"

export interface EnquirySection1Props {
  id: string
  background: HomepageImage
}

const EnquirySection1 = memo((props: EnquirySection1Props) => {
  return (
    <div className={clsx("page-header page-header-small", classes.pageHeader)}>
      <div className="page-header-image">
        <GatsbyImage
          style={{ width: "100%", height: "100%" }}
          alt={props?.background?.alt}
          image={getImage(props?.background?.gatsbyImageData)}
        />
      </div>
    </div>
  )
})

export default EnquirySection1

export const query = graphql`
  fragment EnquirySection1Content on EnquirySection1 {
    id
    background {
      alt
      id
      gatsbyImageData
    }
  }
`
